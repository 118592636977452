.container{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(#121212, #212b46);
}

.titulo{
    font-size: 70px;
    color: blue;
    animation: flipTitulo 2s;
}

@keyframes flipTitulo {
    from{
        transform: rotateX(90deg);
    }
    to {
        transform: rotateX(0deg);
    }
}

.main h2{
    color: yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 16px 0;
    margin-bottom: 16px;    
}

@media (max-width: 620px){
    .titulo{
        font-size: 28px;
    }

    .main h2{
        font-size: 28px;
    }

    .main{
        width: 80%;
    }
}